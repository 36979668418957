import React,{ useState ,useEffect,useRef,useCallback }from 'react';
import { fetchByJson } from "../../../api";
import { tellUserToken } from "../../../auth";
import { message } from "antd"

import FlowContent from "./FlowContent"



const WorkflowContentForTest = [
    {contentid:1,blogid:1,order_index:1000,title:"ユーザの要望を分析",input:0,output:0,params:{},userid:1,type:"var-read",note:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."},
    {contentid:2,blogid:1,order_index:2000,title:"llm1",input:0,output:0,params:{},userid:1,type:"llm",note:"testing..."},
    {contentid:3,blogid:1,order_index:3000,title:"メモをとる",input:0,output:0,params:{},userid:1,type:"var-write",note:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."},
    {contentid:4,blogid:1,order_index:4000,title:"一度返事をする",input:0,output:0,params:{},userid:1,type:"reply-user",note:"testing..."},
    {contentid:5,blogid:1,order_index:4500,title:"深く考える",input:0,output:0,params:{},userid:1,type:"llm",note:"testing..."},
    {contentid:6,blogid:1,order_index:4500,title:"メモをとる",input:0,output:0,params:{},userid:1,type:"note",note:"testing..."},
    {contentid:7,blogid:1,order_index:5000,title:"状況に分けて",input:0,output:0,params:{},userid:1,type:"if-start",note:"testing..."},
    {contentid:8,blogid:1,order_index:6000,title:"RAGデータベースから検索",input:0,output:0,params:{},userid:1,type:"api-rag-normal",note:"testing..."},
    {contentid:9,blogid:1,order_index:6000,title:"Mnemosyneから呼び出し",input:0,output:0,params:{},userid:1,type:"api-mnemosyne",note:"testing..."},
    {contentid:10,blogid:1,order_index:6500,title:"llm1",input:0,output:0,params:{},userid:1,type:"llm",note:"testing..."},
    {contentid:11,blogid:1,order_index:6700,title:"詳細を返事をする",input:0,output:0,params:{},userid:1,type:"reply-user",note:"testing..."},
    {contentid:12,blogid:1,order_index:7000,title:"title",input:0,output:0,params:{},userid:1,type:"if-end",note:"testing..."},
    {contentid:13,blogid:1,order_index:7000,title:"title",input:0,output:0,params:{},userid:1,type:"api-search",note:"testing..."},
]


const App = ( props ) => {

    const gptid = props.gptid
    const width = props.width
    const closePopup = props.closePopup

    const [ifFetchingWorkflowContent,setIfFetchingWorkflowContent] = useState(false)
    const [workflowContent,setWorkflowContent] = useState([])
    const [workflow,setWorkflow] = useState({})

    useEffect(() => {
        fetchWorkflowContent()
      }, []);
    



    const fetchWorkflowContent = async() => {
        try{
            setIfFetchingWorkflowContent(true)
            let data = await fetchByJson("back/mygpt/fetchworkflow", {token:tellUserToken(),gptid:gptid})
            
            console.log(data)
            if (data) {
                if (data.statusCode == 1) {
                    if (data.returnData.workflowContent) {
                        // 使用 sort 方法对列表进行排序
                        const sortedWorkflowContent = data.returnData.workflowContent.sort((a, b) => a.order_index - b.order_index)
                        setWorkflowContent(sortedWorkflowContent);
                    }
                    if (data.returnData.workflow) {
                        setWorkflow(data.returnData.workflow) 
                    }
                }
            } 
            setIfFetchingWorkflowContent(false)
        }catch(error){}
    }


    const addNewBlockAndRequireNewBlockid = async(newBlockOrder) =>{
        
        const info = {
            token:tellUserToken(),
            gptid:gptid,
            newBlockOrder:newBlockOrder
        }
        let data = await fetchByJson("back/mygpt/workflowaddnewblock", info)
    
        console.log(data)
        if (data) {
            if (data.statusCode == 1) {
                return(data.returnData.newContentid)
            }else{
                return(0)
            }
        } else{
        return(0)
        }
    }


    const updateBlockParams = async(contentid,newParamsStr) =>{
        
        const info = {
            contentid:contentid,
            token:tellUserToken(),
            gptid:gptid,
            newParamsStr:newParamsStr
        }
        let data = await fetchByJson("back/mygpt/updateworkflowparams", info)
    
        console.log(data)
        if (data) {
            if (data.statusCode == 1) {
                return(1)
            }else{
                return(0)
            }
        } else{
        return(0)
        }
    }


    const updateBlockType = async(contentid,newType) =>{
        
        const info = {
            contentid:contentid,
            token:tellUserToken(),
            gptid:gptid,
            newType:newType
        }
        let data = await fetchByJson("back/mygpt/updateworkflowtype", info)
    
        console.log(data)
        if (data) {
            if (data.statusCode == 1) {
                return(1)
            }else{
                return(0)
            }
        } else{
        return(0)
        }
    }

    const updateBlockOrder = async(changes) =>{

        try{
          const info = {
            token:tellUserToken(),
            changes:changes,
            gptid:gptid
          }
          let data = await fetchByJson("back/mygpt/updateworkflowindexorder",info)
        
          console.log(data)
    
          if(!data){
            message.error("サーバエラー")
          }
          
          if (data.statusCode != 1) {
            if(data.statusCode == 403){
              message.error("権限がない")
            }else{
              message.error("更新失敗")
            }
            
          }
        }catch(error){message.error("サーバエラー")}
    }

    const deleteBlock = async(contentid) =>{
        
        const info = {
            contentid:contentid,
            token:tellUserToken(),
            gptid:gptid,
        }
        let data = await fetchByJson("back/mygpt/deleteworkflowblock", info)
    
        console.log(data)
        if (data) {
            if (data.statusCode == 1) {
                return(1)
            }else{
                return(0)
            }
        } else{
        return(0)
        }
    }


    

    return (
        <FlowContent
            fetchWorkflowContent = {()=>{}}
            ifFetchingWorkflowContent = {ifFetchingWorkflowContent}
            workflow = {workflow}
            workflowContent = {workflowContent}
            ifOwner = {true}
            updateTitle = {()=>{}}
            addNewBlockAndRequireNewBlockid = {addNewBlockAndRequireNewBlockid}
            updateBlockParams = {updateBlockParams}
            updateBlockType = {updateBlockType}
            updateBlockUrl = {()=>{}}
            updateBlockOrder = {updateBlockOrder}
            deleteBlock = {deleteBlock}
            widthOfChild = {width}
            closePopup = {closePopup}
            // paddingOfChild = {paddingOfChild}

            />
    )


}

export default App