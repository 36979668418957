

export const initBlockParams = (type,params) =>{

    let conpleteParams = {}

    if(type == "llm"){
        conpleteParams = {
            model:params.model,
            temperature:params.temperature,
            withContext:params.withContext,
            prompt:params.prompt,
            title:params.title,
        };

        if(!params.model){
            conpleteParams.model = "openai-gpt-3.5"
        }
        if(!params.temperature){
            conpleteParams.temperature = 0.2
        }
        if(!params.withContext){
            conpleteParams.withContext = 0
        }
        if(!params.prompt){
            conpleteParams.prompt = ""
        }
    }

    if(type == "var-write"){
        conpleteParams = {
            ifAdd:params.ifAdd,
            target:params.target,
            title:params.title,
        };
        if(!params.ifAdd){
            conpleteParams.ifAdd = 0  // overwrite // add
        }
        if(!params.target){
            conpleteParams.target = "var-1"
        }
    }




    if(type == "var-read"){
        conpleteParams = {
            addAhead:params.addAhead,
            addBehind:params.addBehind,
            source:params.source,
            title:params.title,
        };
        if(!params.addAhead){
            conpleteParams.addAhead = ""
        }
        if(!params.addBehind){
            conpleteParams.addBehind = ""
        }
        if(!params.source){
            conpleteParams.source = "user-input"
        }
    }

    if(type == "edit"){
        conpleteParams = {
            addAhead:params.addAhead,
            addBehind:params.addBehind,
            title:params.title,
        };
        if(!params.addAhead){
            conpleteParams.addAhead = ""
        }
        if(!params.addBehind){
            conpleteParams.addBehind = ""
        }
    }

    if(type == "combine"){
        conpleteParams = {
            item1:params.item1,
            item2:params.item2,
            item3:params.item3,
            item4:params.item4,
            item5:params.item5,
            item6:params.item6,
            item7:params.item7,
            item8:params.item8,

            title:params.title,
        };
        if(!params.item1){
            conpleteParams.item1 = "none"
        }
        if(!params.item2){
            conpleteParams.item2 = "none"
        }
        if(!params.item3){
            conpleteParams.item3 = "none"
        }
        if(!params.item4){
            conpleteParams.item4 = "none"
        }
        if(!params.item5){
            conpleteParams.item5 = "none"
        }
        if(!params.item6){
            conpleteParams.item6 = "none"
        }
        if(!params.item7){
            conpleteParams.item7 = "none"
        }
        if(!params.item8){
            conpleteParams.item8 = "none"
        }

    }



    if(type == "note"){
        conpleteParams = {
            note:params.note,
            title:params.title,
        };
        if(!params.note){
            conpleteParams.note = ""
        }
    }

    if(type == "if-start"){
        conpleteParams = {
            title:params.title,
            
            ifCondition1:params.ifCondition1,
            ifCondition2:params.ifCondition2,
            ifCondition3:params.ifCondition3,
            ifCondition4:params.ifCondition4,
            ifCondition5:params.ifCondition5,

            source1:params.source1,
            source2:params.source2,
            source3:params.source3,
            source4:params.source4,
            source5:params.source5,
            
            type1:params.type1,
            type2:params.type2,
            type3:params.type3,
            type4:params.type4,
            type5:params.type5,

            value1:params.value1,
            value2:params.value2,
            value3:params.value3,
            value4:params.value4,
            value5:params.value5,

            gate:params.gate
        }

        if(!params.ifCondition1){
            conpleteParams.ifCondition1 = 1
            conpleteParams.source1 = "var-red"
            conpleteParams.type1 = "equal"
            conpleteParams.value1 = "keyword"
        }
        if(!params.ifCondition2){
            conpleteParams.ifCondition2 = 0
            conpleteParams.source2 = "var-red"
            conpleteParams.type2 = "equal"
            conpleteParams.value2 = "keyword"
        }
        if(!params.ifCondition3){
            conpleteParams.ifCondition3 = 0
            conpleteParams.source3 = "var-red"
            conpleteParams.type3 = "equal"
            conpleteParams.value3 = "keyword"
        }
        if(!params.ifCondition4){
            conpleteParams.ifCondition4 = 0
            conpleteParams.source4 = "var-red"
            conpleteParams.type4 = "equal"
            conpleteParams.value4 = "keyword"
        }
        if(!params.ifCondition5){
            conpleteParams.ifCondition5 = 0
            conpleteParams.source5 = "var-red"
            conpleteParams.type5 = "equal"
            conpleteParams.value5 = "keyword"
        }

        if(!params.gate){
            conpleteParams.gate = "AND"
            
        }
    }

    if(type == "if-end"){
        conpleteParams = {
            title:params.title,
        };
    }

    if(type == "reply-user"){
        conpleteParams = {
            title:params.title,
            input:params.input
        };
        if(!params.input){
            conpleteParams.input = ""
        }
    }

    if(type == "fixed-output"){
        conpleteParams = {
            title:params.title,
            output:params.output
        };
        if(!params.output){
            conpleteParams.output = ""
        }
    }

    if(type == "stop"){
        conpleteParams = {
            title:params.title,
        };
    }

    if(type == "extract-json"){
        conpleteParams = {
            title:params.title,
            tag:params.tag,
            error_message:params.error_message,
        };
        if(!params.tag){
            conpleteParams.tag = "value"
        }
        if(!params.error_message){
            conpleteParams.error_message = "error"
        }
    }


    if(type == "api-mnemosyne"){
        conpleteParams = {
            title:params.title,
        };
    }

    if(type == "api-search"){
        conpleteParams = {
            title:params.title,
        };
    }

    if(type == "api-mail-send"){
        conpleteParams = {
            title:params.title,
            email:params.email,
        };
    }

    




    if(!params.title){
        conpleteParams.title = ""
    }
    return(conpleteParams)


    

}