
import Compo_card_groupchatInfo from "./GroupchatInfo";
import MenuCardItem from "./MenuCardItem";
import ChatCardItem from "./ChatCardItem";
import { Divider } from "antd"

import emoji_stop from "../static/emoji_stop.png";
import emoji_atom from "../static/emoji_atom.png";
import emoji_noentry from "../static/emoji_noentry.png";
import emoji_mail from "../static/emoji_mail.png";
import emoji_cut from "../static/emoji_cut.png";
import emoji_black from "../static/emoji_black.png";
import emoji_a from "../static/emoji_a.png";
import emoji_edit from "../static/emoji_edit.png";
import emoji_package from "../static/emoji_package.png";
import emoji_spark from "../static/emoji_spark.png";



const LeftBar = (props) => {

    const width = props.width
    const height = props.height
    const gptData = props.gptData
    const chatList = props.chatList
    const current_chatid = props.current_chatid
    const onChange_chatid = props.onChange_chatid
    const newChat = props.newChat
    const deleteChat = props.deleteChat

    return(

        <div style={{
            width:width,height:height
            // backgroundColor:"#ffffff",
            }}>
            <div
                style={{
                marginTop:15,marginLeft:15,
                width: width - 15,
                backgroundColor: "#f1f1f1",
                height: height-30,
                float: "left",
                overflowY:"hidden",
                // padding: 20,
                
                borderRadius:12,
                }}
            >

                <Compo_card_groupchatInfo
                    title={ gptData.gptName}
                    current_roombanner={ gptData.gptBanner}
                    description={ gptData.gptDescription}
                    width={width - 15}
                />
                <div style={{height:4}}/>
                <MenuCardItem handleClick={newChat} width={width - 15 - 20} icon={emoji_spark} name={"新規チャット"} badgeNum={1320} active={false} important={false}/>
                
                <div style={{paddingLeft:10,paddingRight:10,marginTop:-15,marginBottom:-20}}>
                    <Divider block style={{height:5}}/> 
                </div>

                <div style={{height:height-300,overflowY:"auto"}}>
                    <div>
                        {chatList.map((item,index)=>{
                            return(
                                <div onClick={()=>{
                                    onChange_chatid(item.chatid)
                                }}
                                >
                                    <ChatCardItem 
                                        handleDelete={deleteChat}
                                        link={"/admin"} width={width - 15 - 20} 
                                        icon={emoji_a} name={item.summary == "" ? "新規チャット":item.summary} 
                                        active={item.chatid==current_chatid}/>
                                </div>
                                
                            )
                        })}
                        <div style={{height:50}}/>
                    </div>  

                </div>
                
                


                <div style={{ height: 10 }} />

                
                
            </div>

        </div>
        
    )
}

export default LeftBar