import React from 'react';
import { message, Space ,Typography,Input} from "antd";
import { Badge} from "antd-mobile"
import { Button} from "antd"
import ImageComponent from "./ImageComponent";
import {
  CommentOutlined,
  LoadingOutlined,
  SettingOutlined,
  LeftOutlined,
  UserOutlined,
  InfoCircleOutlined,
  ReadOutlined,
  MessageOutlined,
  PlusOutlined,
  GlobalOutlined,
  SearchOutlined,
  LockOutlined,
  CalendarOutlined,
  DeleteOutlined
} from "@ant-design/icons";
// import { GlobalOutline, StarOutline, UserOutline } from "antd-mobile-icons";



const Nav = (props) => {
    const icon = props.icon
    const width = props.width
    const name = props.name
    const badgeNum = props.badgeNum
    const active = props.active
    const link = props.link
    const important = props.important
    const handleDelete = props.handleDelete
 
  if(width){
    return (
      <div style={{position:"relative"}}>
        <Button block type="text" style={{
            height:30,
            width:width,
            marginLeft:10,
            // height:44,
            // fontWeight:"bold",
            backgroundColor: active && "#d8d8d8",  //active:3875F6
            borderRadius:8,
            marginBottom:5
            // color: pageName === "share" && "#ffffff",
        }}>
            <Space style={{marginLeft:-5, marginTop:-5,fontSize:14,width:width-15,position:"relative"}}>
              
                {/* < ImageComponent src={icon} alt="hongo" width={23} height={23}></ ImageComponent> */}
               <div style={{
                  width:active ? (width-55):width-35,
                  textAlign:"left",
                  // marginLeft:5,
                  // backgroundColor:"#ff0000",
                  overflow:"hidden",
                  color: "#000000",
                  marginTop:0}}>
                  {name}
                
              </div>
            </Space>
        </Button>
        {active && (
            <Button 
              onClick={handleDelete}
              style={{position:"absolute",top:3.3,right:20}} size = "small" shape='circle' type="text" icon={<DeleteOutlined style={{color:"#666666"}}/>}/>
          )}
      </div>
    );
  }
};

export default Nav