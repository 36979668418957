
function generateNumbers(fromValue, toValue, num) {
    // 确保 from 和 to 是 1000 的倍数，并且 to > from
    if (fromValue % 1000 !== 0 || toValue % 1000 !== 0 || toValue <= fromValue) {
        return "Invalid input";
    }

    // 计算从 from 到 to 之间的 1000 的倍数数量（排除 from 和 to）
    const stepCount = (toValue - fromValue) / 1000 - 1;

    if (num > (toValue - fromValue) - 1) {
        return "fail";
    } else if (num <= stepCount) {
        // 返回 from 到 to 之间的 num 个连续 1000 为间隔的数字，不包括 from 和 to
        let result = [];
        for (let i = 0; i < num; i++) {
            result.push(fromValue + (i + 1) * 1000);
        }
        return result;
    } else {
        // 计算间隔，并构建一个列表以均匀间隔的方式填充 num 个元素，不包括 from 和 to
        let interval = (toValue - fromValue) / (num + 1);
        let result = [];
        for (let i = 1; i <= num; i++) {
            result.push(Math.floor(fromValue + i * interval));
        }
        return result;
    }
}


function longestIncreasingSubsequence(nums) {
    // 最长递增子序列

    // 如果数组为空，直接返回空数组
    if (nums.length === 0) {
        return [];
    }

    // 初始化 dp 数组，每个元素初始值为 1
    // 表示每个元素自身都是长度为 1 的递增子序列
    const dp = new Array(nums.length).fill(1);
    
    // 用于记录前一个元素的索引，初始为 -1，表示没有前驱
    const prevIndex = new Array(nums.length).fill(-1);
    
    // 保存最大长度和最长子序列结束的索引
    let maxLength = 0;
    let maxEndIndex = 0;

    // 遍历每个元素，尝试更新以当前元素结尾的最长子序列长度
    for (let i = 1; i < nums.length; i++) {
        for (let j = 0; j < i; j++) {
            // 如果 nums[i] 能接在 nums[j] 之后并形成更长的子序列
            if (nums[i] > nums[j] && dp[i] < dp[j] + 1) {
                // 更新 dp 和 prevIndex
                dp[i] = dp[j] + 1;
                prevIndex[i] = j;
            }
        }

        // 更新最大长度和对应的索引
        if (dp[i] > maxLength) {
            maxLength = dp[i];
            maxEndIndex = i;
        }
    }

    // 回溯构建最长递增子序列
    const lis = [];
    let currentIndex = maxEndIndex;
    while (currentIndex !== -1) {
        // 添加当前索引对应的元素到子序列
        lis.push(nums[currentIndex]);
        // 更新当前索引为前一元素索引
        currentIndex = prevIndex[currentIndex];
    }

    // 因为是反向构建的，所以最后需要反转顺序
    lis.reverse();
    return lis;
}


const organizeBlocks = (blocks) => {
    // 判断是否已经按 order_index 顺序排列
    if (blocks.every((block, i) => i === blocks.length - 1 || block.order_index < blocks[i + 1].order_index)) {
        // console.log("perfect");
        return { changes: [] };
    }

    // 备份原始 blocks 以进行对比
    const originalBlocks = blocks.map(block => ({ ...block }));

    // 找出所有符合条件的基准 index
    const orgIndices = blocks.map(block => block.order_index);
    // console.log(orgIndices);

    const baseIndices = blocks
        .map(block => block.order_index)
        .filter(order_index => order_index % 1000 === 0);
    // console.log(baseIndices);

    if (baseIndices.length === 0) {
        // 不包含基准 index, 触发全局重新排序.
        return { changes: [], ifFailed: 1 };
    }

    const baseIndicesLis = longestIncreasingSubsequence(baseIndices);
    // console.log(baseIndicesLis);

    const baseIndicesLis2Index = {};
    for (let i = 0; i < baseIndicesLis.length; i++) {
        const indices = baseIndicesLis[i];

        for (let m = 0; m < blocks.length; m++) {
            const orderIndex = blocks[m].order_index;
            if (indices === orderIndex) {
                baseIndicesLis2Index[indices.toString()] = m;
            }
        }
    }

    // console.log(baseIndicesLis2Index);

    // 如果 baseIndicesLis 第一个元素不是 blocks 的第一个元素
    if (baseIndicesLis[0] !== blocks[0].order_index) {
        baseIndicesLis2Index["0"] = 0;
        baseIndicesLis.unshift(0);
    }

    // 如果 baseIndicesLis 最后一个元素不是 blocks 的最后一个元素
    if (baseIndicesLis[baseIndicesLis.length - 1] !== blocks[blocks.length - 1].order_index) {
        baseIndicesLis2Index[(baseIndicesLis[baseIndicesLis.length - 1] + 1000).toString()] = blocks.length;
        baseIndicesLis.push(baseIndicesLis[baseIndicesLis.length - 1] + 1000);
    }

    // console.log(baseIndicesLis2Index);

    const baseIndicesLis2IndexSorted = Object.fromEntries(
        Object.entries(baseIndicesLis2Index).sort((a, b) => parseInt(a[0], 10) - parseInt(b[0], 10))
    );

    // console.log("--------------------------");
    // console.log(baseIndicesLis);
    // console.log(baseIndicesLis2IndexSorted);
    // console.log("--------------------------");

    const changesCandidatesList = [];

    for (let i = 0; i < baseIndicesLis.length - 1; i++) {
        const orderIndexA = baseIndicesLis[i];
        const orderIndexB = baseIndicesLis[i + 1];

        const indexInBlockA = baseIndicesLis2IndexSorted[orderIndexA.toString()];
        const indexInBlockB = baseIndicesLis2IndexSorted[orderIndexB.toString()];

        // console.log(orderIndexA, orderIndexB);
        // console.log(indexInBlockA, indexInBlockB);

        const tempOfCandidates = [];
        for (let indexInBlock = indexInBlockA; indexInBlock < indexInBlockB; indexInBlock++) {
            const orderIndexHere = blocks[indexInBlock];
            if (!baseIndicesLis.includes(orderIndexHere.order_index)) {
                // console.log(orderIndexHere);
                tempOfCandidates.push(orderIndexHere);
            }
        }

        const item = {
            candidates: tempOfCandidates,
            rangeOfOrderIndex: [orderIndexA, orderIndexB]
        };
        // console.log(item);
        changesCandidatesList.push(item);
    }

    const changes = [];
    let ifFailed = false;

    for (let item of changesCandidatesList) {
        const orderIndexFrom = item.rangeOfOrderIndex[0];
        const orderIndexTo = item.rangeOfOrderIndex[1];
        const candidates = item.candidates;

        const newOrderList = generateNumbers(orderIndexFrom, orderIndexTo, candidates.length);
        if (newOrderList === "fail") {
            ifFailed = true;
        } else {
            for (let i = 0; i < candidates.length; i++) {
                changes.push({
                    contentid: candidates[i].contentid,
                    newOrder: newOrderList[i]
                });
            }
        }
    }

    if (ifFailed) {
        return { changes: changes, ifFailed: 1 };
    }

    // console.log("-----------------------");
    // for (let change of changes) {
    //     console.log(change);
    // }

    return { changes: changes };
}
export default organizeBlocks