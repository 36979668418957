import { useState,useRef, useEffect } from "react";
// import Image from 'next/image';
import { Button, message ,Space,Select,Input,Divider,Skeleton,Popconfirm} from "antd"
import { CheckOutlined ,CloseOutlined,HolderOutlined,SmileOutlined,PlusCircleOutlined,LeftOutlined} from "@ant-design/icons"
// import { useDrag, useDrop } from 'react-dnd';
// import { DndProvider } from 'react-dnd';
// import { HTML5Backend } from 'react-dnd-html5-backend';
import BlockContent from "./BlockContent"
import { UpOutlined, DownOutlined ,CommentOutlined ,LinkOutlined ,EyeOutlined,PlusOutlined,ExportOutlined,ImportOutlined} from '@ant-design/icons';
import organizeBlocks from "../tools/organizeBlocks.js"
// import AuthorCard from "./AuthorCard"
// import { Novatrix, Opulento } from "uvcanvas";
// import workflowSetting from "./workflowSetting"
// import FileUploader from "@/components/FileUploader"
import ImageComponent from "../../../component/ImageComponent"
import emoji_moai from "../static/emoji_moai.png";
import emoji_play from "../static/emoji_play.png";
import emoji_stop from "../static/emoji_stop.png";




// -------------------
const orderIndexRate = 1000
// -------------------



function getContentIdsBetweenIfStartAndIfEnd(L) {
    const result = [];
    let collecting = false;
    let tempContentIds = [];

    for (let i = 0; i < L.length; i++) {
        const element = L[i];

        if (element.type === 'if-start') {
            // 开始收集内容
            collecting = true;
            tempContentIds = [];
        } else if (element.type === 'if-end') {
            // 如果是在收集中，此时是if-end，将结果存入result
            if (collecting) {
                result.push(...tempContentIds);
                collecting = false;
            }
        } else if (collecting) {
            // 如果是在收集中，就收集contentid
            tempContentIds.push(element.contentid);
        }
    }
    return result;
}



// 定义一个拖拽项目类型
const ItemType = {
    BLOCK: 'block'
};


//pinBlock
const Block = ({
    ifWithTab,
    block,
    index,
    onUpdateParams,
    onUpdateType,
    // onUpdateUrl,
    onDelete,
    isEditing,
    editingBlockId,
    setEditingBlock,
    ifOwner,
    moveBlock,
    blocksCount, // 接收 blocks 数组的长度
    widthOfChild,
    insertNewBlock
}) => {

    // console.log(ifWithTab)

    const [newParams, setNewParams] = useState(block.params);
    const [editUrl, setEditUrl] = useState(block.url);
    const [isHovered, setIsHovered] = useState(false);

    const handleClick = () => {
        if (ifOwner) {
            setEditingBlock(block.contentid);
        }
    };


    const onChangeParams = (newParams) => {
        // 即时修改的文字组件需要用这个函数修改状态
        if (isEditing) {
            // const newParams = e.currentTarget.textContent;
            setNewParams(newParams) 
            console.log(newParams) 
        }
        
    };

    const handleUpdateType = (newType) => {
        // alert(newType)
        if (newType !== block.type) {
            onUpdateType(block.contentid, newType);
        }
    }

    const handleBlur = () => {
        if (newParams !== block.params) {
            onUpdateParams(block.contentid, newParams);
        }
        setEditingBlock(null); 
    };

    const handleComplete = (e) => {
        e.stopPropagation()
        // if (newParams !== block.params) {
        //     onUpdateParams(block.contentid, newParams);
        // }
        onUpdateParams(block.contentid, newParams);
        setEditingBlock(null); 
    };

    const blockStyle = {
        width:widthOfChild,
        borderRadius: '8px',
        
        marginBottom: isEditing ? '55px' : '15px', // blank between blocks
        transition: 'background-color 0.3s',
        
        // outline: isEditing ? '1px solid #ccc' : 'none',
        minHeight: '40px',
        position: 'relative',
        transition: 'opacity 0.5s ease, width 0.5s ease ,margin-bottom 0.2s ease ',
        overflowWrap: "break-word",
        
    };

    const iconStyle = {
        cursor: 'grab', // 显示一个抓手形状的鼠标指针
        paddingRight: '10px',
    };

    const quoteStyle = {
        fontStyle: 'italic',
        borderLeft: '4px solid #ccc',
        paddingLeft: '10px',
        margin: '0',
        width:"100%",
    };

    let params = {}
    try{
        params = JSON.parse(block.params) 
    }catch(error){}

    // 更新 getContent 使用 BlockContent 组件
    const getContent = () => {
        return (
            <BlockContent
                // block={block}
                ifWithTab={ifWithTab}
                type={block.type}
                params={params}
                contentid={block.contentid}

                // url={block.url}
                isEditing={isEditing}
                // handleBlur={()=> {}}
                onChangeParams={onChangeParams}
                widthOfChild={widthOfChild}
            />
        );
    };

    const handleDelete = (e) => {
        e.stopPropagation();
        onDelete(block.contentid);
        setEditingBlock(null)
    };

    const disabledArrowStyle = {
        cursor: 'not-allowed',
        color: 'rgba(0, 0, 0, 0.25)', // 使用灰色表示禁用状态
    };

    const typeListOfShowUrlEdit = ["link","youtube","image"]

    return (

        <div style={{
            opacity:(!isEditing && editingBlockId) && 0.2,
            // marginTop:isEditing && 20,
            // marginBottom:isEditing && 50,
            transition: 'opacity 0.5s ease',
            }}>

            {isEditing && (<div style={{width:"100%",marginBottom:-10,marginTop:-10}}><Divider/></div>)}

            {ifOwner && 
                <div style={{
                    width:"100%",height:isEditing ? 50:0,
                    overflow:"hidden",
                    // backgroundColor:"#ff0000",
                    justifyContent:"center",
                    transition: 'opacity 0.5s ease, height 0.5s ease',
                    // marginTop:isEditing && 20,
                    // marginBottom:isEditing && 20,
                }}>
                    <Button 
                        block
                        type="text" style={{   
                        // width:200, 
                        height:40,
                        backgroundColor:"#f0f0f0",
                        transition: 'opacity 0.3s ease',
                        color: "#a8a8a8",
                        fontSize:15,
                    }} 
                        onClick={() => {
                            insertNewBlock(block.contentid)
                        }}
                    icon={<PlusCircleOutlined style={{fontSize:15}}/>} >ここにブロックを挿入</Button>
                
                </div>
            }
            {isEditing && (<div style={{width:"100%",marginBottom:-10,marginTop:-20}}><Divider/></div>)}
            <div
                // ref={dropRef}
                style={blockStyle}
                onClick={handleClick}
                // onBlur={handleBlur}
            >
                {/* Drag handle */}
                {/* {isEditing && (
                    <Space direction="vertical" style={iconStyle} ref={dragRef}>
                        <HolderOutlined />
                    </Space>
                )} */}
                {getContent()}
                {typeListOfShowUrlEdit.includes(block.type) && isEditing && (
                    <div style={{
                        width:"100%",
                        marginTop:10,
                    }}>
                        <Input placeholder="your url here" value={editUrl} onChange={(e)=>{setEditUrl(e.target.value)}}/>
                    </div>
                )}

                
                {ifOwner && (
                    <>  
                    {/* pin1 */}
                        <div style={{
                            position: 'absolute',
                            left: 0,
                            bottom:5,
                            width:62,height:31,
                            // backgroundColor:"#f1f1f1",
                            border: '1px solid #ccc',
                            bottom: '-45px',
                            borderRadius:8,
                            opacity: !isEditing && 0,
                            transition: 'opacity 0.3s ease',
                        }}>
                            <Button type="text" style={{
                                position: 'absolute',
                                left: 10,
                                bottom: 3,
                                cursor: 'pointer',
                                fontSize: '16px',
                                opacity: !isEditing && 0,
                                // backgroundColor: "#ffffff",
                                width: isEditing ? 20 : 0,
                                height:30,
                                transition: 'opacity 0.3s ease',
                                cursor: index > 0  ? 'pointer':'not-allowed',
                                color: index > 0  ? '#000000':'rgba(0, 0, 0, 0.25)'
                            }} 
                                onClick={() => index > 0 && moveBlock(index, index - 1)}
                            icon={<UpOutlined />} />

                            <Button type="text" style={{
                                position: 'absolute',
                                left: 30,
                                bottom: 3,
                                cursor: 'pointer',
                                fontSize: '16px',
                                opacity: !isEditing && 0,
                                width: isEditing ? 20 : 0,
                                height:30,
                                // backgroundColor: "#ffffff",
                                transition: 'opacity 0.3s ease',
                                cursor: index < blocksCount - 1 ? 'pointer':'not-allowed',
                                color: index < blocksCount - 1 ? '#000000':'rgba(0, 0, 0, 0.25)'
                            }} 
                                onClick={() => index < blocksCount - 1 && moveBlock(index, index + 1)} 
                            icon={<DownOutlined />} />

                        </div>
                        
                        <Select value={block.type} onChange={(value) => {
                            handleUpdateType(value)
                        }} 
                            suffixIcon={false}
                            style={{
                                // backgroundColor:"#ff0000",
                                position: 'absolute',
                                left: 70,
                                bottom: -47,
                                opacity: !isEditing && 0,
                                height:35,
                                width: 150,
                                transition: 'opacity 0.3s ease',
                                // padding: '5px',
                                // border: '1px solid #ccc',
                                borderRadius: '5px',
                        }}>
                            <option value="var-read">⤴️ 変数を読み取る</option>
                            <option value="var-write">⤵️ 変数に書き込み</option>
                            <option value="edit">✂️ 出力を編集</option>
                            <option value="combine">✂️ 編集を結合</option>
                            <option value="llm">❇️ LLM</option>
                            <option value="reply-user">ℹ️ ユーザに返事</option>
                            <option value="note">メモ</option>
                            <option value="if-start">❓ 条件開始</option>
                            <option value="if-end">❔ 条件終了</option>
                            <option value="stop">⛔ 会話終了</option>
                            <option value="api-mnemosyne">♏ Mnemosyne</option>
                            <option value="api-search">⚛️ 検索</option>
                            <option value="api-mail-send">📩 メール</option>
                            <option value="extract-json">✂️ Jsonを抽出</option>
                            <option value="fixed-output">✂️ 固定値出力</option>
                        </Select>




                        <Button type="text" style={{
                            position: 'absolute',
                            right: 0,
                            bottom: '-45px',
                            cursor: 'pointer',
                            color: '#1CE484',
                            fontSize: '16px',
                            opacity: !isEditing && 0,
                            backgroundColor: "#f1f1f1",
                            width: isEditing ? 80 : 0,
                            height:36,
                            transition: 'opacity 0.2s ease, width 0.5s ease '
                        }} onClick={handleComplete} icon={<CheckOutlined />} />

                        <Popconfirm
                            title="Delete the task"
                            description="Are you sure to delete this task?"
                            onConfirm={handleDelete}
                            // onCancel={cancel}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button type="text" danger style={{
                                position: 'absolute',
                                right: 90,
                                bottom: '-45px',
                                cursor: 'pointer',
                                opacity: !isEditing && 0,
                                fontSize: '16px',
                                backgroundColor: "#fff1f1f1",
                                width: isEditing ? 40 : 0,
                                height:36,
                                transition: 'opacity 0.2s ease, width 0.5s ease'
                            }} icon={<CloseOutlined />} />
                        </Popconfirm>
                        
                    </>
                )}
            </div>  
            {isEditing && (<div style={{width:"100%",marginBottom:-10,marginTop:60}}><Divider/></div>)}

        </div>
    );
};


       



























const App = (props) => {

    // pinApp
    const widthOfChild = props.widthOfChild
    // const paddingOfChild = props.paddingOfChild

    const closePopup = props.closePopup
    const workflow = props.workflow;
    const workflowContent = props.workflowContent;
    const fetchworkflowContent = props.fetchworkflowContent
    const ifOwner = props.ifOwner;
    // const ifOwner = false
    const ifFetchingworkflowContent = props.ifFetchingworkflowContent;
    const updateTitle = props.updateTitle
    const addNewBlockAndRequireNewBlockid = props.addNewBlockAndRequireNewBlockid

    const [blocks, setBlocks] = useState([]);
    const [contentidListThatWithTab,setContentidListThatWithTab] = useState([])
    // const [blocksBeforeEdit, setBlocksBeforeEdit] = useState([]);
    const [title, setTitle] = useState("");
    const [editingBlockId, setEditingBlockId] = useState(null);
    // const [finalChanges,setFinalChanges] =  useState([]);
   

    useEffect(() => {
        setBlocks(workflowContent)
        setContentidListThatWithTab(getContentIdsBetweenIfStartAndIfEnd(workflowContent))
        console.log(workflowContent);
    }, [workflowContent]);

    // useEffect(() => {
    //     setTitle(workflow.title);
    //     console.log(workflow);
    // }, [workflow]);

    useEffect(() => {
        const handleKeyPress = (event) => {
          // 检查是否按下了 command + s 或者 ctrl + s
          if ((event.metaKey || event.ctrlKey) && event.key === 's') {
            event.preventDefault(); // 阻止浏览器默认行为，比如页面保存
            message.config({top: document.documentElement.clientHeight - 70});
            message.info("こちらのフローは自動的にセーブしています😏")
          }
        };
        // 添加键盘事件监听器
        window.addEventListener('keydown', handleKeyPress);
    
        // 清除函数
        return () => {
          window.removeEventListener('keydown', handleKeyPress);
        };
      }, []);


    const startAndFinishEditingBlock = (id) => {
        setEditingBlockId(id)

        // 结束修改状态blocks的内容
        if(!id){
            // 对于被修改后的状态 blocks 进行整理
            const result = organizeBlocks(blocks)
            if(result.changes && !result.ifFailed){
                const changes = result.changes
                let newBlocks = [...blocks]
                changes.map((c)=>{
                    newBlocks.map((b)=>{
                        if(b.contentid == c.contentid){
                            b.order_index = c.newOrder
                        }
                    })
                })
                if(changes.length != 0){
                    props.updateBlockOrder(changes)
                }
                
            }
            if(result.ifFailed){
                // 触发重新读区blocks的值
                // ...
            }
        }
    }

    


    const addBlock = async() => {
        let previousOrder = 0
        if(blocks.length != 0){
            previousOrder = blocks[blocks.length - 1].order_index
        }
        const newBlockOrder = previousOrder + orderIndexRate
        const newContentid = await addNewBlockAndRequireNewBlockid(newBlockOrder)
        if(newContentid){
            const type = "llm"
            const params = '{"title":"名無しブロック"}'
            const newBlock = {
                contentid: newContentid,
                type:type,
                params:params,
                order_index:previousOrder + orderIndexRate
            };
            setBlocks((prevBlocks) => [...prevBlocks, newBlock]);
            setContentidListThatWithTab(getContentIdsBetweenIfStartAndIfEnd((prevBlocks) => [...prevBlocks, newBlock]))

            setEditingBlockId(newContentid)
        }
    };

    const insertNewBlock = async(contentid) => {
        
        let indexInList = 0
        let currentOrder = 0
        let previousOrder = 0
        for(let index in blocks){
            if(blocks[index].contentid == contentid){
                indexInList = index
                currentOrder = blocks[index].order_index
            }
        }
        if(indexInList != 0){
            previousOrder = blocks[indexInList - 1].order_index
        }
        const newBlockOrder = Math.floor((previousOrder + currentOrder) / 2)

     
        const newContentid = await addNewBlockAndRequireNewBlockid(newBlockOrder)
        if(newContentid){
            const type = "llm"
            const params = '{"title":"名無しブロック"}'
            const newBlock = {
                contentid: newContentid,
                type:type,
                params:params,
                order_index:newBlockOrder
            };
            let newBlocks = [...blocks, newBlock]
            newBlocks.sort((a, b) => a.order_index - b.order_index);
            setBlocks(newBlocks);
            // setContentidListThatWithTab(getContentIdsBetweenIfStartAndIfEnd(newBlocks))
            setEditingBlockId(newContentid)
        }
    };

    const updateBlockParams = (id, newParams) => {

        // console.log(id)
        // console.log(newParams)
        const newParamsStr = JSON.stringify(newParams)

        props.updateBlockParams(id,newParamsStr)
        const updatedBlocks = blocks.map((block) =>
            block.contentid === id ? { ...block, params: newParamsStr } : block
        );
        setBlocks(updatedBlocks);
        setContentidListThatWithTab(getContentIdsBetweenIfStartAndIfEnd(updatedBlocks))
    };


    const updateBlockType = (id, newType) => {
        props.updateBlockType(id,newType)
        console.log(newType)
        const updatedBlocks = blocks.map((block) =>
            block.contentid === id ? { ...block, type: newType } : block
        );
        setBlocks(updatedBlocks);
        setContentidListThatWithTab(getContentIdsBetweenIfStartAndIfEnd(updatedBlocks))
    };

    const deleteBlock = (id) => {

        props.deleteBlock(id)
        const remainingBlocks = blocks.filter((block) => block.contentid !== id);
        setBlocks(remainingBlocks);
        setContentidListThatWithTab(getContentIdsBetweenIfStartAndIfEnd(remainingBlocks))
    };

    const handleTitleBlur = (e) => {
        const newTitle = e.currentTarget.textContent.trim();
        if (newTitle && newTitle !== title) {
            setTitle(newTitle);
            updateTitle(newTitle); // Call the updateTitle function from props
        }
    };

    const moveBlock = (fromIndex, toIndex) => {
        const updatedBlocks = [...blocks];
        const [movedBlock] = updatedBlocks.splice(fromIndex, 1);
        updatedBlocks.splice(toIndex, 0, movedBlock);
        setBlocks(updatedBlocks);
        setContentidListThatWithTab(getContentIdsBetweenIfStartAndIfEnd(updatedBlocks))
    };

    // console.log(contentidListThatWithTab)
 

    if(!ifFetchingworkflowContent){
        return (
            <div style={{
                // backgroundColor:"#0000ff"
                position:"relative"
                }}>    
                

                <div style={{height:20}} />
                <Button type="ghost" style={{backgroundColor:"#f1f1f1"}} icon={<LeftOutlined/>} onClick = {closePopup}>戻る</Button>

                <Space style={{position:"absolute",right:0}}>
                    <Button type="link" icon={<ExportOutlined />}>Export</Button>
                    <Button style={{marginLeft:-20}} type="link" icon={<ImportOutlined />}>Import</Button>
                </Space>
                
                <div style={{width:"100%",marginTop:-5,marginBottom:-10}}><Divider/></div>
                <div style={{borderRadius:10,
                    padding:10,
                    backgroundColor: '#f0f0f0',
                    // outline:  isEditing && '1px solid #ccc',
                    height:60,
                    overflow:"hidden",
                    transition: 'height 0.5s ease',
                    marginTop:10,

                    }}>
                    {/* <div style={{height:10}} /> */}
                    <div style={{float:"left",width:60,height:60,overflow:"hidden"}}>
                        <ImageComponent 
                            src = {emoji_play}
                            width = {60} 
                            height = {60} 
                        />
                    </div>
                    <div style={{float:"left",width:widthOfChild - 90,marginLeft:10,}}>
                        <div style={{fontSize:18,fontWeight:"bold",marginTop:5}}>ユーザ入力</div>
                        <div style={{fontSize:15,marginTop:5,color:"#a8a8a8"}}>click to edit global setting</div>
                    </div>
                </div>



                <div style={{
                    width: "100%",
                    justifyContent:"center",
                    textAlign:"center",fontSize:20,
                    marginTop:10,marginBottom:10
                    
                    }}>⬇️</div>



                {blocks.map((block, index) => (
                    <Block
                        ifWithTab = {contentidListThatWithTab?.includes(block.contentid)}
                        key={block.contentid}
                        index={index}
                        block={block}
                        ifOwner={ifOwner}
                        onUpdateParams={updateBlockParams}
                        onUpdateType={updateBlockType}
                        // onUpdateUrl={updateUrl}
                        onDelete={deleteBlock}
                        isEditing={editingBlockId === block.contentid}
                        editingBlockId={editingBlockId}
                        setEditingBlock={startAndFinishEditingBlock}
                        moveBlock={moveBlock} // Add this prop
                        blocksCount={blocks.length}
                        widthOfChild={widthOfChild}
                        insertNewBlock={insertNewBlock}
                    />
                ))}
                {ifOwner && (
                    <div onClick={()=>{startAndFinishEditingBlock(null)}}>
                        {/* <div style={{height:10}}></div> */}
                        <div style={{
                            // width:"100%",
                            borderRadius: '8px',
                            // padding: '10px',
                            transition: 'background-color 0.3s',
                            // backgroundColor: '#f1f1f1' ,
                            // outline: '1px solid #ccc',
                            // minHeight: '40px',
                            position: 'relative',
                            transition: 'opacity 0.5s ease, width 0.5s ease ,margin-bottom 0.2s ease ',
                            overflowWrap: "break-word",
                            // opacity: isDragging ? 0.5 : 1,
                            display: 'flex',
                            alignItems: 'center',
                            // paddingLeft:10
                            // marginTop:-5,
                        }}
                        >
                           
                            <Button 
                                block
                                type="text" style={{   
                                // width:200, 
                                height:70,
                                backgroundColor:"#f0f0f0",
                                transition: 'opacity 0.3s ease',
                                color: "#a8a8a8",
                                fontSize:20,
                            }} 
                            onClick={()=>{addBlock()}}
                            icon={<PlusCircleOutlined style={{fontSize:20}}/>} 
                            >ブロックを追加</Button>
                        </div>
                    </div>
                    
                )}
                
                

                <div style={{
                    width: "100%",
                    justifyContent:"center",
                    textAlign:"center",fontSize:20,
                    marginTop:10,marginBottom:10
                    
                    }}>⬇️
                </div>

                <div style={{borderRadius:10,
                    padding:10,
                    backgroundColor: '#f0f0f0',
                    // outline:  isEditing && '1px solid #ccc',
                    height:60,
                    overflow:"hidden",
                    transition: 'height 0.5s ease',
                    marginTop:10,

                    }}>
                    {/* <div style={{height:10}} /> */}
                    <div style={{float:"left",width:60,height:60,overflow:"hidden"}}>
                        <ImageComponent 
                            src = {emoji_stop}
                            width = {60} 
                            height = {60} 
                        />
                    </div>
                    <div style={{float:"left",width:widthOfChild - 90,marginLeft:10,marginTop:5}}>
                        <div style={{fontSize:18,fontWeight:"bold"}}>終わり</div>
                        <div style={{fontSize:15,marginTop:5,color:"#a8a8a8"}}>ワークフローが終了する</div>
                    </div>
                </div>
                <div style={{height:20}}></div>

            </div>

        );

    }else{
        return(
            <div>
                <div
                    // contentEditable={ifOwner}
                    // suppressContentEditableWarning
                    // onBlur={handleTitleBlur}
                    // onClick={()=>{startAndFinishEditingBlock(null)}}
                    style={{ 
                        // padding:paddingOfChild,
                        paddingTop:10,paddingBottom:10 
                }}>
                    <div style={{width:widthOfChild > 400 ? 400 : widthOfChild,marginTop:5 }}>
                        <Skeleton.Button active size={"large"} shape={"square"} block/>
                    </div>
                    <div style={{height:35}}/>
                    <Skeleton active />
                    <div style={{height:50}}/>
                    <Skeleton active />
                    <div style={{height:50}}/>
                    <Skeleton active />
                    <div style={{height:50}}/>
                </div>
                
            

            </div>
        )
    }
    
};
export default App;