

import React, { Component} from "react";
import {withRouter} from 'react-router-dom'
// import copy from "copy-to-clipboard"
// import Dashboard from './Dashboard'
import { Divider,Typography,Tooltip} from 'antd';
import { Tranquiluxe } from "uvcanvas"
import { Space, Upload ,Popover,Menu,Avatar} from 'antd';
// import { Button} from 'antd';
// import { message,Drawer} from 'antd';
// import * as CryptoJS from 'crypto-js'
// import cookie from 'react-cookies'
// import { MailOutlined, SettingOutlined } from '@ant-design/icons';
import banner from "../static/bg.jpg"
import ImageComponent from "./ImageComponent"

import { 
	RightOutlined,
	LoadingOutlined,
	UserOutlined,
	UserAddOutlined,
	CloseOutlined,
	AppstoreOutlined,
	PlusCircleOutlined,
	CloudUploadOutlined,
	PlusOutlined,
} from '@ant-design/icons';


const { Title, Paragraph, Text, Link } = Typography;




class Compo_card_groupchatInfo extends Component {


	
	//pinState
	state={
		//switch_ifSureLeaveroom:false,
	}
	
	
	render() {

		const width = this.props.width
		//var height_memberBar = this.myRef_member_div.current.clientHeight

		// var width = document.documentElement.clientWidth
		var height = document.documentElement.clientHeight
		
		var title = this.props.title
		var description = this.props.description
		var icon = this.props.icon

		if(title === undefined){title = "undefined"}
		if(description === undefined){description = "undefined"}
		if(icon === undefined || icon == 0){icon = false}

		var iconType = "img"
		if(icon == "newRobot"){
			iconType = "newRobot"
		}
		if(icon == "importRobot"){
			iconType = "importRobot"
		}

		var banner_here = this.props.current_roombanner
		//console.log(this.props.current_roombanner)
		if(banner_here == 0 || banner_here == "" || banner_here == "0"){
			banner_here = 0
		}


		// pinReturn
		return(
		
			

			<div 
				onClick={this.props.onClick_callback}
				style={{
					
					height:200,
					width:width-20,
					// backgroundColor:"#ffff00",
					marginLeft:10,marginTop:10
				}}>
					<div 
						onClick = {this.props.callBack}
						style={{
							
							// margin:-10,
							// marginTop:-5,
							backgroundColor:"#e3e3e3",
							height: "auto",//230,
							width:width-20,
							borderRadius:9,
							cursor:"pointer",
							overflow:"hidden",
							
							
						}}>
						{banner_here == 0 ? (
							<Tranquiluxe style={{width:width-20,
								height:140
							}}/>
						):(
							<ImageComponent src = {banner_here} width = {width-20} height = {140} />
						)}
						
						<div style={{height:0}} />
						<Space style={{paddingLeft:5}}>
							
							
							<div style={{
								height:"auto",//90,
								width:width-45,marginLeft:7,marginRight:7,overflow:"hidden"}}>

								<div style={{height:7}} />
								<div style={{fontSize:15,fontWeight:"bold",height:23,overflow:"hidden",whiteSpace:"nowrap"}}>
								
									<Tooltip title={title}>
										{title}
									</Tooltip>
								</div>
								<div style={{fontSize:11,paddingTop:3,
									height:"auto",//50,
									marginBottom:10,
									overflow:"hidden",
									whiteSpace:"pre-wrap"}}>
									<Tooltip title={description}>
										{description}
									</Tooltip>
								</div>
							</div>
						</Space>

						
					</div>
				
				




				

				


				
				

			</div>






			
			
			
			
		)

  	}
}

export default withRouter(Compo_card_groupchatInfo);