
// api - v1.0

import { env_serverAddress } from "./env";

export var fetchByJson = async (url, args) => {
  try{
    args["ARG_TEST_TIME"] = 0;
    let response = await fetch(env_serverAddress + url, {
      method: "post",
      body: JSON.stringify(args),
      headers: { "Content-Type": "application/json" },
    });
    let result = await response.json();
    return result;
  }catch(error){
    console.log(error)
    return(0)
  }
  
};
