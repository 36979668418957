import React from 'react';
import { message, Space ,Typography,Input} from "antd";
import { Badge} from "antd-mobile"
import { Button} from "antd"
import ImageComponent from "./ImageComponent";
import {
  CommentOutlined,
  LoadingOutlined,
  SettingOutlined,
  LeftOutlined,
  UserOutlined,
  InfoCircleOutlined,
  ReadOutlined,
  MessageOutlined,
  PlusOutlined,
  GlobalOutlined,
  SearchOutlined,
  LockOutlined,
  CalendarOutlined
} from "@ant-design/icons";
// import { GlobalOutline, StarOutline, UserOutline } from "antd-mobile-icons";



const Nav = (props) => {
    const icon = props.icon
    const width = props.width
    const name = props.name
    const badgeNum = props.badgeNum
    const active = props.active
    const link = props.link
    const important = props.important
    const handleClick = props.handleClick
 
  if(width){
    return (
      <div onClick={handleClick}>
        <Button block type="text" style={{
            width:width,
            marginLeft:10,
            height:44,fontWeight:"bold",
            backgroundColor: important ? "#000000":(active && "#888888"),  //active:3875F6
            borderRadius:8
            // color: pageName === "share" && "#ffffff",
        }}>
            <Space style={{marginLeft:-5, marginTop:4,fontSize:14,width:width-15,position:"relative"}}>
              
                < ImageComponent src={icon} alt="hongo" width={23} height={23}></ ImageComponent>
              
              
              
            
            <div style={{width:width-95,
                textAlign:"left",marginLeft:5,
                // backgroundColor:"#ff0000",
                overflow:"hidden",
                color:important ? "#ffffff":(active ? "#ffffff":"#000000"),
                marginTop:0}}>
                {name}
            </div>
            {badgeNum != undefined && badgeNum != 0 && (
                <Badge content={""+badgeNum} style={{position:"absolute",right:12,top:4}}/>
            )}
            </Space>
        </Button>
      </div>
    );
  }
};

export default Nav