// import cookie from 'react-cookies'


export function isValidUrl(url) {
  // 检查字符串是否以 "https://" 开头 并且 以 ".png" 结尾
  return url.startsWith("https://") && url.endsWith(".png");
}

export function isTxt(str) {
  return str.includes(".txt");
}
export function isXlsx(str) {
  return str.includes(".xlsx");
}


export function tellifyesterday(date) {
  var today = new Date(); // 获取今天的日期
  var yesterday = new Date(today.getTime() - 24 * 60 * 60 * 1000); // 获取昨天的日期
  var targetDate = new Date(date.year, date.month - 1, date.day); // 指定日期

  // 判断指定日期是否等于昨天的日期
  if (
    targetDate.getFullYear() === yesterday.getFullYear() &&
    targetDate.getMonth() === yesterday.getMonth() &&
    targetDate.getDate() === yesterday.getDate()
  ) {
    return true;
  }
  
  return false;
}


export function formatDate(timestamp) {
    const date = new Date(timestamp);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const hour = date.getHours();
    const minute = date.getMinutes();
    const second = date.getSeconds();
  
    return `${year}.${month}.${day}.${hour}.${minute}.${second}`;
  }

export function formatDate_tellDay(timestamp) {
    const date = new Date(timestamp);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const hour = date.getHours();
    const minute = date.getMinutes();
    const second = date.getSeconds();
  
    return day
  }
  
export function formatDate_tellDate(timestamp) {
    const date = new Date(timestamp);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const hour = date.getHours();
    const minute = date.getMinutes();
    const second = date.getSeconds();

    return `${year}.${month}.${day}`;
  }
export function formatHourAndMinute(timestamp) {
    const date = new Date(timestamp);
    const hour = date.getHours();
    const minute = date.getMinutes();
  
    return `${hour}:${minute}`;
}


// export function ifHasToken(cookie_key) {
//     var token_here = cookie.load(cookie_key)	
//     if(token_here != undefined){
//       return true
//     }
//     else{
//       return false
//     }

// }






export function ifPressCommandAndEnter(event, callback) {
  const isCommandKey = navigator.userAgent.indexOf('Mac') !== -1 ? event.metaKey : event.ctrlKey;
  const isEnterKey = event.keyCode === 13;
  if (isCommandKey && isEnterKey) {
    event.preventDefault();
    callback();
  }
}


export function containsEmoji(str) {
  const emojiPattern = /[\u{1F600}-\u{1F64F}|\u{1F300}-\u{1F5FF}|\u{1F680}-\u{1F6FF}|]/gu;
  return emojiPattern.test(str);
}

export function containsSQLInjection(str) {
  const sqlInjectionPattern = /[`'\"\\]|(ALTER|CREATE|DELETE|DROP|INSERT|SELECT|UPDATE|TRUNCATE|REPLACE|FROM|WHERE|UNION|GROUP)$/gi;
  return sqlInjectionPattern.test(str);
}



export function extractJSONsAndText(str) {
  const resultList = [];
  let stack = [];
  let startIndex = 0;
  let quote = '';
  let nonJsonStartIndex = 0;
  
  for (let i = 0; i < str.length; i++) {
    const char = str[i];

    if (char === '{' && !quote) {
      if (stack.length === 0) {
        startIndex = i;
        if (nonJsonStartIndex !== startIndex) {
          resultList.push(str.slice(nonJsonStartIndex, i));
        }
      }
      stack.push('{');
    } else if (char === '}' && !quote) {
      stack.pop();
      if (stack.length === 0) {
        const jsonString = str.slice(startIndex, i + 1);
        try {
          resultList.push(JSON.parse(jsonString));
          nonJsonStartIndex = i + 1;
        } catch (error) {
          console.error(`Invalid JSON at startIndex ${startIndex}`, jsonString);
        }
      }
    } else if (char === '"' || char === "'") {
      if (!quote) {
        quote = char;
      } else if (char === quote) {
        quote = '';
      }
    } else if (char === '\\' && quote) {
      i++; // Skip escaped character
    }
  }
  
  if (nonJsonStartIndex !== str.length) {
    resultList.push(str.slice(nonJsonStartIndex));
  }

  return resultList;
}